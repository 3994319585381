import React, { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";
import jsPDF from "jspdf";
import image from "./platStet.png";
import image2 from "./qr.png";
import backgroundImage from "./waselg1.png";
import { RWebShare } from "react-web-share";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import Navbar from "./component/Navbar";

function Certificate() {
  const [searchValue, setSearchValue] = useState("");
  const [data2, setData] = useState([]);
  const [visible, setVisible] = useState("hidden");
  console.log(visible);

  useEffect(() => {
    const certificate_nos = async () => {
      try {
        const response = await axios.get(
          "https://nimc-backend-iaus.onrender.com/api/fetchcert"
        );
        setData(response.data.users);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    certificate_nos();
  }, []);
  useEffect(() => {
    if (searchValue.length === 10) {
      setVisible("visible");
    }
  }, [searchValue]);

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  const generatePdf = (user) => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [11, 8.5],
    });

    // Add background image to PDF
    doc.addImage(
      backgroundImage,
      "PNG",
      0,
      0,
      doc.internal.pageSize.getWidth(),
      doc.internal.pageSize.getHeight()
    );

    // Add image to PDF
    doc.addImage(
      image2,
      "PNG",
      9, // Adjusted X-coordinate
      7, // Adjusted Y-coordinate
      1.5, // Adjusted width
      1.5, // Adjusted height
      { rounded: 2 } // Rounded corner radius (adjust as needed)
    );
    doc.addImage(
      `${user.Imagedata}`,
      "JPEG",
      9.3, // Adjusted X-coordinate
      1, // Adjusted Y-coordinate
      1.5, // Adjusted width
      1.5, // Adjusted height
      { rounded: 2 } // Rounded corner radius (adjust as needed)
    );

    // Add text to PDF using user data
    doc.setFontSize(20);
    doc.text(`${user.Names}`, 2, 5.15); // Adjusted position
    doc.setFontSize(20);
    doc.text(`${user.date}`, 7.1, 7.25);
    doc.setTextColor(255, 0, 0); // Red color (RGB values)
    doc.setFontSize(25);
    doc.text(`${searchValue}`, 9, 6.8); // Adjusted position

    // Save PDF
    doc.save("certificate.pdf");
  };

  // Filter users based on searchValue
  const filteredUsers = data2.filter((user) => {
    const applicationNos = user.Application_nos
      ? user.Application_nos.toString()
      : "";
    return applicationNos.includes(searchValue.toString());
  });

  return (
    <>
      <div className="background">
        <Navbar />
        <form>
          <h1 className="text-white text-center display-1 py-5 fw-normal">
            {} Download your Certificate of Origin
          </h1>
          <div className="row mb-3">
            <label
              htmlFor="inputPassword3"
              className="col-lg-1 col-form-label"
            ></label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="Input the Application Number"
                id="seachValue"
                value={searchValue}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
        {filteredUsers.map((user) => (
          <div
            className="mx-auto col-lg-10 col-sm-10 col-md-10 hello"
            style={{ visibility: visible, right: "0px" }}
          >
            <div className="card mx-auto mb-3 border rounded shadow">
              <div className="row no-gutters">
                <div className="col-md-4 text-center p-5">
                  <img
                    src={image}
                    style={{ width: "200px", height: "200px" }}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title text-sm-center">
                      Certificate of Indigene
                    </h5>
                    <p className="card-text text-sm-center">
                      <b>Name:</b>&nbsp;&nbsp; {user.Names} <br></br>
                      <b>Certificate Nos:</b>&nbsp;&nbsp; {user.Application_nos}
                      <br></br>
                    </p>
                    <div className="text-center col-sm-6 col-md-8 col-lg-8 mx-auto">
                      <a className="btn btn-dark btn-sm-center mx-3 mb-2">
                        <div className="card">
                          <div className="card-top"></div>{" "}
                          <div className="card-bottom">
                            <button
                              onClick={() => generatePdf(user)}
                              className="btn btn-success"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      </a>

                      <RWebShare
                        data={{
                          text: `check out my latest certificate :101101`,
                          url: `certificate`,
                          title: `Certificate of indegene`,
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <a className="btn btn-dark btn-sm-center mx-3">
                          <span>Share 🔗</span> <br></br>
                          <span>
                            <FaTwitter size={15} />
                            <FaFacebook size={15} />
                            <FaLinkedin size={15} />
                          </span>
                        </a>
                      </RWebShare>
                    </div>

                    <p className="card-text">
                      <small className="text-muted">
                        Wase Local Government Certificate of Indigene
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Certificate;
