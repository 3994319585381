import { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Login() {
  const initialValues = {
    username: "",
    password: "",
    confirmPassword: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [token, setToken] = useState("");
  console.log(formValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    try {
      const response = await axios.post(
        "https://wasebackend-f5uu.onrender.com/api/login",
        {
          username: formValues.username, // Assuming formValues contains username and password
          password: formValues.password,
        }
      );

      // Handle successful login based on backend response (e.g., store token)
      const token = response.data.token;
      setToken(token);
      // Store the token in local storage or session storage for later use
      localStorage.setItem("token", token);
      // Simulate a delay before navigating
      setTimeout(() => {
        // Redirect the user to another page upon successful login
        navigate("/dashboard"); // Assuming "/dashboard" is the path to the next page
      }, 5000);

      // Optionally, redirect the user to another page upon successful login
      // history.push("/dashboard");
    } catch (error) {
      console.error("Error during login:", error);
      // setErrorMessage(error.response?.data?.message || "Login failed");
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors, formValues, isSubmit]);
  const validate = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = "Username is required!";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Those passwords didn’t match. Try again.";
    }
    return errors;
  };

  return (
    <>
      <div className="bgImg">
        <div className="container">
          {token ? (
            <div className="ui message success">Signed in successfully</div>
          ) : isSubmit ? (
            <div className="ui message error">Signed in unsuccessfully</div>
          ) : null}

          <form onSubmit={handleSubmit}>
            <h1>Wase Admin Login</h1>
            <div className="ui divider"></div>
            <div className="ui form">
              <div className="field">
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Choose a username"
                  value={formValues.username}
                  onChange={handleChange}
                />
              </div>
              <p>{formErrors.username}</p>

              <div className="field">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formValues.password}
                  onChange={handleChange}
                />
              </div>
              <p>{formErrors.password}</p>
              <div className="field">
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  value={formValues.confirmPassword}
                  onChange={handleChange}
                />
              </div>
              <p>{formErrors.confirmPassword}</p>
              <button className="fluid ui button blue">Submit</button>
            </div>
          </form>
        </div>{" "}
      </div>
    </>
  );
}

export default Login;
