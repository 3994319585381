import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Certificate from "./Certificate";
import Datacollection from "./Datacollection";
import PrivateRoute from "./utils/Privateroute";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Certificate />}></Route>
          <Route path="/data" element={<Datacollection />}></Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
