import React from "react";
import logo from "../platStet.png";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <>
      <div
        className="container-fluid fixed-top scrollspy text-white"
        style={{ background: "#272626", position: "relative" }}
      >
        <nav class="navbar navbar-expand-lg navbar-dark">
          <div class="container-fluid">
            <a href="/" className="text-decoration-none">
              <div className="h3 d-flex ">
                <img
                  src={logo}
                  style={{ height: "50px", width: "50px", marginRight: "6px" }}
                />
                <span
                  className=" align-self-center font-weight-bolder px-2 "
                  style={{ color: "green" }}
                >
                  Wase
                </span>
                <span
                  className=" align-self-center font-weight-bold"
                  style={{ color: "green" }}
                >
                  Local Government Admin
                </span>
              </div>
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link className="nav-link text-white px-5" to="/dashboard">
                    Print Certificate
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-white" to="/data">
                    Data Collection
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
